import * as React from "react"
import NotFound from "@components/404/NotFound"
import Seo from "@components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <NotFound />
  </>
)

export default NotFoundPage

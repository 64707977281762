import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getToken } from "@http/client"
import { detectMob } from "@src/Helper"

const NotFound = () => {
  return (
    <div
      id={`${detectMob() ? "m-notFound" : "notFound"}`}
      className="not-found"
    >
      <div className="container flex-col-a-center pad-t-125">
        <StaticImage
          className="not-found__image"
          src="../../images/404-not-found.png"
          alt="404 Not Found"
          width={344}
          height={344}
        />
        <h1 className="not-found__title heading-5">404 Not Found</h1>
        <p className="not-found__description">
          Maaf, kami tidak dapat menemukan pipeho.me yang kamu cari. Buat
          pipehome kamu sekarang!
        </p>
        <Link
          to={`${getToken() ? "/dashboard" : "/"}`}
          className="not-found__button button button-md button-primary"
        >
          Kembali ke Sociopipe
        </Link>
      </div>
    </div>
  )
}

export default NotFound
